@tailwind base;
@tailwind components;
@tailwind utilities;

.active{
  color: #19c2c1;
  @apply dark:text-main-pink
}

.active{
  @apply dark:text-main-pink
}


.girl_bg{
    position: relative;
    width: 500px;
    height: fit-content;
    overflow: hidden;
    z-index: 3;
  }
  .select *{
    border: none !important;
  }
  .girl_bg::before{
    background-color: #feC14A;
    border-radius: 50%;
    z-index: 1;
    content: '';
    position: absolute;
    width: 100%;
    height: 87%;
    bottom: -6px;
  }

  .girl_bg::before{
    @apply dark:bg-main-pink
  }

 
  .slick-dots li button::before{
    @apply !text-main-blue dark:!text-main-pink  !w-0 !transition-all !duration-700 dark:!bg-main-pink
   }
   .slick-active{
     @apply !px-2 !transition-all !duration-500
   }
   .slick-dots button:first-child {
     @apply !w-0 !transition-all !duration-500
   }
   .slick-active button:first-child {
     @apply !w-[40px] !absolute !bottom-[-5px] !rounded-lg  !right-0 !h-[4px] !bg-main-blue dark:!bg-main-pink duration-700
   }
   .slick-active button:first-child  {
     @apply !w-[40px] !absolute !bottom-[-5px] !rounded-lg  !right-0 !h-[4px] !bg-main-blue dark:!bg-main-pink
   }
   .slick-active button::before{
     @apply  !text-[0px] 
   }

   



   @media screen and (max-width: 1200px) {
    .MuiInputBase-root,
  .MuiInput-root,
  .MuiInput-underline {
      position: relative;
      right: 5px;
    }
  }
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  
  .MuiInputBase-root svg,
  .MuiInput-root svg,
  .MuiInput-underline svg {
    display: none;
  }
  
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  
  .MuiSelect-select,
  .MuiSelect-select {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  
  #footer-section {
    background-color: #0f386d;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 600px) {
    #footer-section .Col-For-Links {
      margin-top: 30px;
    }
  }